<script setup lang="ts">
import { toArray } from '#core/utils/array'

const props = withDefaults(
  defineProps<{
    src?: string | string[]
    size?: keyof typeof sizes | string
    fallbackImage?: string
    max?: number
  }>(),
  {
    src: '/images/avatars/user.svg',
    size: 'sm',
    fallbackImage: '/images/avatars/user.svg',
  },
)

const sizes = {
  xs: 'h-5 w-5',
  sm: 'h-6 w-6',
  md: 'h-8 w-8',
  lg: 'h-12 w-12',
}

const images = computed(() => toArray(props.src).slice(0, props.max))

const sizeClass = computed(() => {
  return sizes[props.size] || props.size || sizes.sm
})

function handleImageError(index: number) {
  images.value[index] = props.fallbackImage
}
</script>

<template>
  <div data-ui="UiAvatar" class="flex -space-x-2 items-center shrink-0">
    <img
      v-for="(image, index) in images"
      :key="image"
      :src="image"
      :class="sizeClass"
      class="rounded-full border border-forgd-bgd-600 bg-white"
      @error="handleImageError(index)"
    >
    <div
      v-if="max !== undefined && src.length > max"
      class="flex items-center justify-center rounded-full border border-forgd-bgd-600 bg-white"
      :class="sizeClass"
    >
      <span class="text-xs text-primary-900 font-medium truncate">+{{ src.length - max }}</span>
    </div>
  </div>
</template>
